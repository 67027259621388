import { z } from "zod";
import { UseFormRegister } from "react-hook-form";

import { clsx, numberToFix } from "core";
import { Text } from "components/Text";
import { Label } from "../../../../../components/Label";
import { InvestorProfileFormInputs } from "./newInvestorProfile.schema";

interface RadioOrCheckboxProps {
  name: keyof InvestorProfileFormInputs;
  label: string;
  type: "radio" | "checkbox";
  align?: "vertical" | "horizontal";
  subTitle?: string;
  value?: string;
  className?: string;
  defaultValue?: number;
  disabled?: boolean;
  register: UseFormRegister<InvestorProfileFormInputs>;
}

export interface InvestorProfileListItem {
  value: string;
  subTitle?: boolean;
}
export interface InvestorProfileListGraphqItem extends InvestorProfileListItem {
  icon?: string;
  hasTitle?: boolean;
  description?: boolean;
}
interface RadioWithCheckboxProps
  extends Pick<RadioOrCheckboxProps, "name" | "value" | "register" | "align"> {
  list: InvestorProfileListItem[];
  i18nKey: string;
  subTitle?: boolean;
  checkbable?: boolean;
}

interface ListWrapperProps {
  i18nKey: string;
  children: React.ReactNode;
}

// Lists
function ListWrapper({ i18nKey, children }: ListWrapperProps) {
  return (
    <div className="mb-6">
      <Text
        label={`page.profil.form.${i18nKey}.title`}
        className="bg-grey-400 mb-2 pl-5"
      />
      <div className="pl-5">{children}</div>
    </div>
  );
}
export function RadioList({
  name,
  list,
  i18nKey,
  register,
  ...props
}: Omit<RadioWithCheckboxProps, "value">) {
  return (
    <ListWrapper i18nKey={i18nKey}>
      {list.map((item, index) => (
        <InputElement
          key={`${item.value}-${index}`}
          type="radio"
          name={name}
          value={item.value}
          subTitle={
            item.subTitle ? `page.profil.form.${i18nKey}.subTitle` : undefined
          }
          register={register}
          align={props.align}
          label={`page.profil.form.${i18nKey}.${index + 1}`}
        />
      ))}
    </ListWrapper>
  );
}

export function CheckBoxList({
  data,
  i18nKey,
  register,
  ...props
}: {
  data: Record<string, string>;
  register: UseFormRegister<InvestorProfileFormInputs>;
  i18nKey: string;
  align?: RadioOrCheckboxProps["align"];
}) {
  return (
    <ListWrapper i18nKey={i18nKey}>
      {Object.entries(data).map(([key, value], index) => (
        <InputElement
          type="checkbox"
          key={`${key}-${index}`}
          value={value}
          name={key as keyof InvestorProfileFormInputs}
          register={register}
          label={`page.profil.form.${i18nKey}.${index + 1}`}
          {...props}
        />
      ))}
    </ListWrapper>
  );
}

export function RadioWithCheckboxList({
  data,
  i18nKey,
  checkbable = true,
  register,
  ...props
}: {
  data: {
    key: string;
    list: {
      subTitle?: boolean;
      list: InvestorProfileListItem[];
    }[];
  };
  register: UseFormRegister<InvestorProfileFormInputs>;
  i18nKey: string;
  align?: RadioOrCheckboxProps["align"];
  checkbable?: boolean;
}) {
  return (
    <ListWrapper i18nKey={i18nKey}>
      {data.list.map(({ list, subTitle }, index) => {
        const key = `${data.key}.${index + 1}`;

        return (
          <RadioWithCheckbox
            key={key}
            checkbable={checkbable}
            subTitle={subTitle}
            name={key as keyof InvestorProfileFormInputs}
            list={list}
            register={register}
            i18nKey={key}
            {...props}
          />
        );
      })}
    </ListWrapper>
  );
}

// Elements
export function RadioWithCheckbox({
  name,
  list,
  value,
  subTitle,
  checkbable = true,
  i18nKey = "q3a.3",
  register,
  ...props
}: RadioWithCheckboxProps) {
  const _label = `page.profil.form.${i18nKey}.check`;
  const _subTitle = subTitle
    ? `page.profil.form.${i18nKey}.subTitle`
    : undefined;

  return (
    <div
      className={clsx(
        "mb-2",
        props.align === "horizontal" && "flex justify-between"
      )}
    >
      {checkbable ? (
        <InputElement
          type="checkbox"
          value={value}
          name={`${name}Check` as keyof InvestorProfileFormInputs}
          register={register}
          subTitle={_subTitle}
          label={_label}
        />
      ) : (
        <Label
          label={_label}
          subTitle={_subTitle}
          className="cursor-pointer text-base"
        />
      )}
      <div
        className={clsx(
          "ml-4 flex flex-col gap-2 px-2",
          checkbable && "bg-gray-50",
          props.align === "horizontal" && "flex-row items-center"
        )}
      >
        {list.map((item, index) => (
          <InputElement
            key={`${item.value}-${index}`}
            type="radio"
            name={name}
            value={item.value}
            subTitle={
              item.subTitle ? `page.profil.form.${i18nKey}.subTitle` : undefined
            }
            register={register}
            label={`page.profil.form.${i18nKey}.${index + 1}`}
            {...props}
          />
        ))}
      </div>
    </div>
  );
}
function InputElement({
  name,
  type,
  label,
  align = "vertical",
  value,
  subTitle,
  disabled,
  className,
  register,
}: RadioOrCheckboxProps) {
  const id = `${name}-${value}`;

  return (
    <div
      className={clsx(
        "flex gap-2",
        align === "vertical" && "items-center",
        className
      )}
    >
      <input
        type={type}
        id={id}
        value={value}
        {...register(name, {
          disabled,
        })}
      />
      <div>
        <Label
          id={id}
          label={label}
          subTitle={subTitle}
          className="cursor-pointer text-base"
        />
      </div>
    </div>
  );
}

export function RadioWithGraph({
  name,
  list,
  i18nKey,
  register,
  ...props
}: Omit<RadioWithCheckboxProps, "value" | "list"> & {
  list: InvestorProfileListGraphqItem[];
}) {
  return (
    <ListWrapper i18nKey={i18nKey}>
      <div className="flex items-stretch">
        {list.map((item, index) => (
          <Item key={`${item.value}-${item}`} item={item} index={index + 1} />
        ))}
      </div>
    </ListWrapper>
  );

  function Item({
    item,
    index,
  }: {
    index: number;
    item: InvestorProfileListGraphqItem;
  }) {
    return (
      <div key={index} className="flex flex-1 flex-col justify-between ">
        {!!item.hasTitle && (
          <Text
            className="px-2 text-center"
            label={`page.profil.form.${i18nKey}.${index}.title`}
          />
        )}
        <div>
          <img src={item.icon} className="mb-4 block" />
          {item.description && (
            <Label
              label={`page.profil.form.${i18nKey}.${index}.description`}
              className="cursor-pointer text-base"
            />
          )}
          <InputElement
            type="radio"
            name={name}
            value={item.value}
            subTitle={
              item.subTitle ? `page.profil.form.${i18nKey}.subTitle` : undefined
            }
            register={register}
            align={props.align}
            label={`page.profil.form.${i18nKey}.${index}.label`}
          />
        </div>
      </div>
    );
  }
}

// Helpers
export function makeZodBoolFromObject(obj: object) {
  return z.object(
    Object.keys(obj).reduce((prev, curr) => {
      return { ...prev, [curr]: z.boolean() };
    }, {})
  );
}

type DataType = InvestorProfileFormInputs;

type DataTypekey = keyof DataType;

function calculateAverage(data: DataType, key: DataTypekey): number {
  let sum = 0;
  let count = 0;
  const subData = data[key];

  if (subData === null) {
    return 0;
  }

  if (typeof subData === "number") {
    return subData;
  }

  for (const subKey in subData as unknown as object) {
    let value = subData?.[subKey as keyof typeof subData] as unknown as
      | string
      | number;
    if (typeof value === "string") {
      value = Number(value);
    }
    if (!isNaN(value)) {
      sum += value;
      count++;
    }
  }
  return count === 0 ? 0 : sum / count;
}

function calculateSum(data: DataType, key: DataTypekey): number {
  const subData = data[key];

  if (subData === null) {
    return 0;
  }

  if (typeof subData === "number") {
    return subData;
  }

  let sum = 0;

  if (Array.isArray(subData)) {
    for (const value of subData) {
      const numValue = typeof value === "string" ? Number(value) : value;
      if (!isNaN(numValue) && numValue !== false) {
        sum += numValue;
      }
    }
  } else {
    for (const subKey in subData as unknown as object) {
      const value = subData?.[subKey as keyof typeof subData] as unknown as
        | string
        | number;

      const numValue = typeof value === "string" ? Number(value) : value;
      if (!isNaN(numValue)) {
        sum += numValue;
      }
    }
  }

  return sum;
}

export function calcInvestorProfileStats(): number[] {
  const results: number[] = [];
  for (let i = 1; i <= 6; i++) results.push(0);

  return results;
}

export function _calcInvestorProfileStats(data?: DataType): {
  risk: number;
  experience: number;
  sensitivity: number;
  negativeImpacts: number;
  environmentActivities: number;
  environmentSocialGoal: number;
} {
  if (!data) {
    data = {} as DataType;
  }

  const valr: { [key: number]: number } = {};
  const nbquestion: { [key: number]: number } = {
    1: 5,
    2: 9,
    3: 4,
    4: 1,
    5: 1,
    6: 1,
  };
  const divisors: { [key: number]: number } = {
    1: 3.6,
    2: 0.84,
    3: 0.93,
    4: 0.66,
    5: 1,
    6: 2,
  };

  valr[1] =
    calculateAverage(data, "q2") +
    calculateSum(data, "q3a" as DataTypekey) +
    calculateSum(data, "q3b" as DataTypekey) +
    calculateSum(data, "q4") +
    calculateSum(data, "q5");
  valr[2] =
    calculateAverage(data, "q1") +
    calculateSum(data, "q6") +
    calculateSum(data, "q7") +
    calculateSum(data, "q8") +
    calculateSum(data, "q9a") +
    calculateSum(data, "q9b") +
    calculateAverage(data, "q14") +
    calculateSum(data, "q15") +
    calculateSum(data, "q16");
  valr[3] =
    calculateSum(data, "q10") +
    calculateSum(data, "q11") +
    calculateSum(data, "q12") +
    calculateSum(data, "q13");
  valr[4] = calculateAverage(data, "q17");
  valr[5] = calculateAverage(data, "q18");
  valr[6] = calculateSum(data, "q19");

  return {
    experience: Math.round(valr[1] / nbquestion[1] / divisors[1]),
    risk: Math.round(valr[2] / nbquestion[2] / divisors[2]),
    sensitivity: Math.round(valr[3] / nbquestion[3] / divisors[3]),
    environmentActivities: Math.round(valr[4] / nbquestion[4] / divisors[4]),
    environmentSocialGoal: Math.round(valr[5] / nbquestion[5] / divisors[5]),
    negativeImpacts: Math.round(valr[6] / nbquestion[6] / divisors[6]),
  };
}

function calculateSustainableInvestmentScore(
  sustainableInvestment: InvestorProfileFormInputs["sustainableInvestment"]
): number {
  let score = 0;

  if (!sustainableInvestment) return score;

  // 1. Include ESG Dimension (0-1 point)
  if (
    sustainableInvestment.includeEnvironmentalSocialGovernanceDimension !==
    "YES"
  )
    return score;

  score += 1;

  // 2. Definition of Sustainability Component (0-1 point)
  if (
    sustainableInvestment.optionsDefineSustainabilityComponent ===
    "DEFINE_IT_PRECISELY_ANSWERING_4_QUESTIONS"
  ) {
    score += 1;
  } else if (
    sustainableInvestment.optionsDefineSustainabilityComponent ===
    "AGREE_FOLLOW_ESG_POLICY"
  ) {
    score += 0.5;
  }

  // 5. Preferred ESG Dimension (0-0.5 point)
  switch (sustainableInvestment.preferredASGDimension) {
    case "NO_PREFERENCE":
      score += 0.1;
      break;
    case "ENVIRONMENTAL":
    case "SOCIAL":
    case "GOOD_GOVERNANCE":
      score += 0.5;
      break;
    default:
      break;
  }

  switch (sustainableInvestment.taxonomyAlignment) {
    case "0_25":
      score += 0.25;
      break;
    case "26_50":
      score += 0.5;
      break;
    case "51_75":
      score += 0.75;
      break;
    case "76_100":
      score += 1;
      break;
    default:
      break;
  }

  // 6. Exclude Negative Activities (0-0.5 point)
  if (
    sustainableInvestment.excludeNegativeActivitiesEnvironmentalSocial === "YES"
  ) {
    // score += 0.5;

    // 7. Issues to Minimize Negative Impacts (Bonus: 0-0.5 point)
    if (sustainableInvestment.issuesMinimizeNegativeImpacts) {
      const selectedIssues = Object.values(
        sustainableInvestment.issuesMinimizeNegativeImpacts
      ).filter(Boolean);

      // loop limit 5
      for (let i = 0; i < 5; i++) {
        if (selectedIssues[i]) {
          score += 0.1;
        }
      }
    }
  }

  // Cap the score at a maximum of 5 points
  // only take one decimal if the score is not an integer

  return numberToFix(Math.min(score, 5), 2);
}
function calculateInstrumentsScore(
  instruments: InvestorProfileFormInputs["financialKnowledgeAndExperience"]["instruments"]
): number {
  const maxScorePerInstrument = 3;
  const totalInstruments = Object.keys(instruments).length;
  const maxTotalScore = totalInstruments * maxScorePerInstrument; // Max possible score is 33 points for 11 instruments
  let totalScore = 0;

  Object.keys(instruments).forEach((instrumentKey) => {
    const instrument = instruments[instrumentKey as keyof typeof instruments];

    // 1. Connaissance (Knowledge)
    let instrumentScore = 0;
    const holdingAndRisk =
      instrument?.knowledgeActivityInFinancialProductsInstruments || [];
    const knowledge = instrument?.knowledge || "none";

    if (knowledge === "basic") {
      instrumentScore += 0.5; // Connaissance basique: 0.5 point
    } else if (knowledge == "good") {
      instrumentScore += 1; // Bonne connaissance: 1 point
    }

    // 2. Risque de perte en capital (Risk of capital loss)
    if (holdingAndRisk.includes("RISK_CAPITAL_LOSS")) {
      instrumentScore += 0.5;
    }

    // 3. Détention (Holdings)
    if (holdingAndRisk.includes("HOLDINGS")) {
      instrumentScore += 0.5;
    }

    // 4. Nombre de transactions sur les 12 derniers mois (Transactions in the last 12 months)
    switch (instrument?.transactionsNumberLast12Months) {
      case "LESS_3":
        instrumentScore += 0.1;
        break;
      case "BETWEEN_3_12":
        instrumentScore += 0.3;
        break;
      case "MORE_12":
        instrumentScore += 0.5;
        break;
      default:
        break;
    }

    // 5. Montant des investissements sur les 12 derniers mois (Investment amount in the last 12 months)
    switch (instrument?.investmentsAmountMadeLast12Months) {
      case "LESS_1000":
        instrumentScore += 0.1;
        break;
      case "BETWEEN_1000_10000":
        instrumentScore += 0.3;
        break;
      case "MORE_10000":
        instrumentScore += 0.5;
        break;
      default:
        break;
    }

    totalScore += instrumentScore;
  });

  // Normalize the total score to 20 points scale
  const normalizedScore = (totalScore / maxTotalScore) * 20;

  return normalizedScore;
}
function calculateFinancialKnowledgeExperienceLastPartScore(
  financialKnowledge: InvestorProfileFormInputs["financialKnowledgeAndExperience"]
) {
  let score = 0;
  if (!financialKnowledge) return score;

  // 1. Investor Rate Scoring
  switch (financialKnowledge.investissorRate) {
    case "NOVICE":
      score += 1;
      break;
    case "FAIRLY_EXPERIENCED":
      score += 2;
      break;
    case "EXPERIENCED":
      score += 3;
      break;
    case "HIGHLY_EXPERIENCED":
      score += 4;
      break;
    default:
      break;
  }

  // 2. Financial Investment Time Scoring
  switch (financialKnowledge.financialInvestmentsTime) {
    case "0_2_YEARS":
      score += 1;
      break;
    case "3_5_YEARS":
      score += 2;
      break;
    case "6_10_YEARS":
      score += 3;
      break;
    case "11_20_YEARS":
    case "MORE_20_YEARS":
      score += 4;
      break;
    default:
      break;
  }

  // 3. Experience of Gains and Losses Scoring
  switch (financialKnowledge.bestDescribes) {
    case "BOTH_GAINS_&_LOSSES":
      score += 2;
      break;
    case "MOSTLY_GAINS":
      score += 3;
      break;
    case "MOSTLY_LOSSES":
      score += 1;
      break;
    default:
      break;
  }

  // 4. Reaction to Drop in Value (0-3 points)
  switch (financialKnowledge.observedDecreaseValue) {
    case "WAIT_DETAILS":
      score += 1;
      break;
    case "NOT_CHANGE":
      score += 2;
      break;
    case "REINVESTED_SAME":
      score += 3;
      break;
    default:
      break;
  }

  // 5. Knowledge of Management Modes (0-1.5 points)
  if (financialKnowledge.knownManagementModes) {
    const knownModesCount = Object.values(
      financialKnowledge.knownManagementModes
    ).filter((value) => !!value && value !== "NONE").length;
    score += Math.min(knownModesCount * 0.5, 1.5); // Max 1.5 points
  }

  // 6. Chosen Management Modes (0-1.5 points)
  if (financialKnowledge.choiceManagementModes) {
    const chosenModesCount = Object.values(
      financialKnowledge.choiceManagementModes
    ).filter((value) => !!value && value !== "NONE").length;
    score += Math.min(chosenModesCount * 0.5, 1.5); // Max 1.5 points
  }

  // 7. Information Sources for Investments (0-5 points)
  if (financialKnowledge.informationSourcesForInvestments) {
    let infoSourcesScore = 0;
    const sources = financialKnowledge.informationSourcesForInvestments;

    // Mapping based on key-value pairs
    Object.values(sources).forEach((source) => {
      switch (source) {
        case "SURROUNDINGS":
          infoSourcesScore += 0.25;
          break;
        case "GENERAL_PRESS":
          infoSourcesScore += 0.5;
          break;
        case "ECONOMIC_&_FINANCIAL_PRESS":
          infoSourcesScore += 1;
          break;
        case "TELEVISION_RADIO":
          infoSourcesScore += 0.5;
          break;
        case "SPECIALIZED_WEBSITES":
          infoSourcesScore += 1;
          break;
        case "PROFESSIONAL":
          infoSourcesScore += 1;
          break;
        case "SOCIAL_NETWORKS":
          infoSourcesScore += 0.25;
          break;
        default:
          break;
      }
    });

    // Cap the score at a maximum of 5 points
    score += Math.min(infoSourcesScore, 5);
  }

  return score;
}

function calculateFinancialKnowledgeExperienceScore(
  financialKnowledge: InvestorProfileFormInputs["financialKnowledgeAndExperience"]
): number {
  let score = 0;

  if (financialKnowledge?.instruments) {
    score += calculateInstrumentsScore(financialKnowledge.instruments);
  }

  score +=
    calculateFinancialKnowledgeExperienceLastPartScore(financialKnowledge);

  return score;
}

export const normalizeToMaxFive = (value: number) => Math.ceil((value * 5) / 7);

export function newProfileInvestForm(
  investorProfileForm: InvestorProfileFormInputs
) {
  const donutData = calcInvestorProfileStats();

  let knowledgeAndExperienceResult = donutData[0];
  let riskProfileResult = donutData[1];
  let sustainableInvestmentResult = donutData[2];
  const isFirstVersion = investorProfileForm?.version === "1";
  const hasManualSri = investorProfileForm?.manualSri !== undefined;

  if (!isFirstVersion) {
    if (investorProfileForm) {
      // Calculate knowledge and experience
      const knowledgeAndExperienceScore =
        calculateFinancialKnowledgeExperienceScore(
          investorProfileForm?.financialKnowledgeAndExperience
        );

      if (knowledgeAndExperienceScore < 9) {
        knowledgeAndExperienceResult = 0;
      } else if (knowledgeAndExperienceScore < 17) {
        knowledgeAndExperienceResult = 1;
      } else {
        knowledgeAndExperienceResult = 2;
      }

      // Calculate sustainable investment
      const sustainableInvestmentScore = calculateSustainableInvestmentScore(
        investorProfileForm.sustainableInvestment
      );

      if (sustainableInvestmentScore < 1.5) {
        sustainableInvestmentResult = 0;
      } else if (sustainableInvestmentScore < 3) {
        sustainableInvestmentResult = 1;
      } else if (sustainableInvestmentScore < 5) {
        sustainableInvestmentResult = 3;
      } else {
        sustainableInvestmentResult = 4;
      }
    }
    const finalSri = investorProfileForm?.final_sri;

    const hasSri = !Number.isNaN(finalSri);

    if (hasSri) {
      riskProfileResult = finalSri;
      if (finalSri === 7) {
        riskProfileResult = 6;
      }
    }
    riskProfileResult = normalizeToMaxFive(riskProfileResult);
  }

  return {
    knowledgeAndExperienceResult,
    riskProfileResult,
    sustainableInvestmentResult,
    isFirstVersion,
    hasManualSri,
  };
}
